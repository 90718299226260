<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('lager-type-modal', {id: 0},shown,{width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.lagerType.label.name') }}</b-th>
                            <b-th>{{ $t('settings.lagerType.label.categories') }}</b-th>
                            <b-th>{{ $t('settings.lagerType.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="lagerType in lagerTypes" :key="lagerType.id">
                            <b-td>{{ lagerType.name }}</b-td>
                            <b-td>{{ formatCategory(lagerType.categories) }}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('lager-type-modal', {id: lagerType.id},shown,{width: '800px'})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteLagerType(lagerType.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsLagerTypes',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            lagerTypes: [],
        }
    },
    methods: {
        ...mapGetters('LagerTypes', ['getLagerTypes']),
        ...mapGetters('Category', ['getCategories']),
        shown() {
            const lagerTypesFetch = this.$store.dispatch('LagerTypes/fetchLagerTypes')
            const categories = this.$store.dispatch('Category/fetchCategories')

            Promise.all([lagerTypesFetch, categories])
                .then(() => {
                    this.lagerTypes = this.getLagerTypes()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteLagerType(id) {
            this.$removeConfirm('LagerTypes/deleteLagerType', id, this.shown)
        },
        formatCategory(categories) {
            let result = [];
            categories = categories.map(function (x) {
                return parseInt(x);
            });
            Object.entries(this.getCategories()).forEach(function (value, index) {
                if (categories.includes(value[1].id)) {
                    result.push(value[1].name);
                }
            });
            return result.join(', ');
        }
    },
    mounted() {
        this.shown()
    }
}
</script>